.icon-row {
    @include container(0, 100px);
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: auto auto;
    text-align: center;

    > * {
        padding: 25px 15px 15px;
    }

    img {
        width: 60px;
        height: 60px;
        margin-bottom: 25px;
    }

    h4 {
        font-weight: 400;
    }

    > * {
        grid-row: 1
    }

    > :nth-child(1) {
        grid-column: 1;
    }

    > :nth-child(2) {
        grid-column: 2;
    }

    > :nth-child(3) {
        grid-column: 3;
    }

    > :nth-child(4) {
        grid-column: 4;
    }

    &.seals {
        @include containerMobile(5em, 5em);

        h3 {
            color: $primary_blue;
            grid-row: 1;
            grid-column: 1 / 5;
        }

        > :nth-child(2) {
            grid-column: 1;
            grid-row: 2;
        }

        > :nth-child(3) {
            grid-column: 2;
            grid-row: 2;
        }

        > :nth-child(4) {
            grid-column: 3;
            grid-row: 2;
        }

        > :nth-child(5) {
            grid-column: 4;
            grid-row: 2;
        }
    }
}

@include mobile {
    .icon-row {
        @include containerMobile(0px, 80px);
        grid-template-columns: 100%;
        grid-template-rows: auto;
        row-gap: 50px;

        > * {
            padding: 0;
        }

        &:not(.seals) {
            > :nth-child(1) {
                grid-column: 1;
                grid-row: 1;
            }

            > :nth-child(2) {
                grid-column: 1;
                grid-row: 2;
            }

            > :nth-child(3) {
                grid-column: 1;
                grid-row: 3;
            }

            > :nth-child(4) {
                grid-column: 1;
                grid-row: 4;
            }
        }

        &.seals {
            @include containerMobile(5em, 5em);
            grid-template-columns: 50% 50%;
            grid-template-rows: auto 30px auto 30px auto;
            row-gap: 0px;

            h3 {
                grid-row: 1;
                grid-column: 1 / 3;
            }
    
            > :nth-child(2) {
                grid-column: 1;
                grid-row: 3;
            }
    
            > :nth-child(3) {
                grid-column: 2;
                grid-row: 3;
            }
    
            > :nth-child(4) {
                grid-column: 1;
                grid-row: 5;
            }
    
            > :nth-child(5) {
                grid-column: 2;
                grid-row: 5;
            }

            h4 {
                font-size: 81.25%;
            }
        }
    }
}
