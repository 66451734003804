@import "mixins";

.cookie-banner {
  position: fixed;
  z-index: 100;
  bottom: 1em;
  left: 1em;
  right: 1em;
  padding: 1em;
  border-radius: 1em;
  font-size: 110%;
  box-shadow: 0 0 1.5em 0 rgba(0, 0, 0, 0.85);
  opacity: 1.0;
  transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  background: linear-gradient(-90deg, darken($primary, 15%), darken($secondary, 15%));
  color: #fff;
  text-align: center;
  border: solid 1px #fff;

  .btn {
    display: inline-block;
    margin-left: 1em;
  }

  .ok {
    @include bt-filled(darken($primary, 25%), $white);
  }

  .cancel {
    @include bt-light-bordered($white);
  }

  .ok, .cancel {
    margin: 0.5em;
    width: 8em;
  }

  a {
    text-decoration: underline;
    white-space: nowrap;
  }

  a:hover {
    opacity: 0.9;
  }
}

.cookie-banner-hidden {
  opacity: 0.0;
  bottom: -2em;
}