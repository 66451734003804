.footer-wrapper {
    background: $gradient_blue;
    position: relative;
    width: 100%;

    .footer {
        @include container(230px, 20px);
        display: grid;
        grid-template-columns: 20% 30px 15% 30px auto 30px 50%;
        grid-template-rows: auto 120px auto;
        align-content: center;
        color: #fff;
        line-height: 24px;

        > :nth-child(1) {
            grid-row: 1;
            grid-column: 1;
            display: grid;
            grid-template-columns: 40px 1em auto;

            .icon-logo-white {
                width: 40px;
                height: 40px;
                grid-column: 1;
            }

            .brand-title {
                grid-column: 3;
                align-self: center;
                color: rgb(248, 248, 248);
                font-family: 'Roboto';
                font-size: 150%;
                font-weight: 700;
                white-space: nowrap;
            }
        }

        > :nth-child(2) {
            grid-row: 2;
            grid-column: 1;
            justify-self: center;
        }

        > :nth-child(3) {
            grid-row: 2;
            grid-column: 3;

            > :nth-child(1) {
                font-weight: 500;
                letter-spacing: 1px;
            }
        }

        > :nth-child(4) {
            grid-row: 3;
            grid-column: 1;
            padding-top: 50px;
            white-space: nowrap;
        }

        > :nth-child(5) {
            grid-row: 3;
            grid-column: 7;
            font-weight: 500;
            padding-top: 50px;
            justify-self: flex-end;

            > * {
                padding-right: 30px;
            }
        }
    }
}

@include mobile {
    .footer-wrapper {
        .footer {
            @include containerMobile(80px, 20px);
            grid-template-columns: 100%;
            gap: 10px;
            background-position: left center;

            > :nth-child(1) {
                grid-row: 1;
                grid-column: 1;
            }

            > :nth-child(2) {
                grid-row: 2;
                grid-column: 1;
                justify-self: flex-start;
            }

            > :nth-child(3) {
                grid-row: 3;
                grid-column: 1;
            }

            > :nth-child(4) {
                grid-row: 4;
                grid-column: 1;
                padding-top: 20px;
            }

            > :nth-child(5) {
                grid-row: 5;
                grid-column: 1;
                padding-top: 20px;
            }
        }
    }
}