.intro {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto 10px auto;
    margin-top: 6%;

    p {
        grid-column: 2;
        grid-row: 1;
        text-align: center;
        color: #ffffff;

        font-size: 125%;
    }

    .headline {
        grid-column: 2;
        grid-row: 1;
        max-width: 786px;
        justify-self: center;

        color: #ffffff;
        font-size: 300%;
        font-weight: 700;
        text-align: center;
    }

    .subheader {
        grid-column: 2;
        grid-row: 3;
        max-width: 786px;
        justify-self: center;

        color: rgb(255, 255, 255);
        font-size: 112.5%;
        text-align: center;
        line-height: 27px;
    }

    &.with-icon-row {
        grid-template-rows: auto auto 90px auto;

        > :nth-child(1) {
            grid-column: 2;
            grid-row: 1;
        }

        > :nth-child(2) {
            grid-column: 2;
            grid-row: 2;
        }

        > :nth-child(3) {
            grid-column: 2;
            grid-row: 4;
        }
    }

    @include mobile {
        @include containerMobile(60px, 0);
        margin: 0;

        grid-template-columns: 100%;
        grid-template-rows: auto;


        .headline  {
            grid-column: 1;
            grid-row: 1;
            
            font-size: 200%;
        }

        .subheader {
            grid-column: 1;
            grid-row: 2;
            align-self: flex-start;

            font-size: 114%;
            padding-bottom: 50px;
            text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
        }

        &.with-icon-row {
            grid-template-rows: auto;
            
            > :nth-child(1) {
                grid-column: 1;
                grid-row: 1;
            }
    
            > :nth-child(2) {
                grid-column: 1;
                grid-row: 2;
                padding-bottom: 50px;
            }
    
            > :nth-child(3) {
                grid-column: 1;
                grid-row: 3;
            }
        }

    }
}