@import "colors";
@import "helpers";

@mixin bt-base($color1:$flex-dark-grey, $color2:$flex-green, $padding:0.5em 1em) {

  display: inline-block;
  zoom: 1;
  line-height: normal;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  user-select: none !important;
  box-sizing: border-box;
  padding: $padding;
  outline: 0 !important;
  position: relative;
  color: $color1;
  transition: all 0.2s ease;
  background: none;

  @include no-select;

  &:hover {
    color: $color2;
  }
}

@mixin bt($color: $flex-green) {

  @include bt-base(#fff, #fff);

  background-color: darken($color, 2.5%);
  border: solid 1px darken($color, 5.0%);
  border-radius: 0.15em;
  text-shadow: 0 0 0.25em darken($color, 25%);

  &:hover:enabled {
    background-color: lighten($color, 2.5%);
    border: solid 1px darken($color, 2.5%);
    box-shadow: 0 0.1em 0.4em 0 rgba(0, 0, 0, 0.35);
    text-shadow: 0 0 0.35em darken($color, 25%);
  }

  &:active:enabled {
    transition: none;
    background-color: lighten($color, 2.5%);
    text-shadow: none;
    box-shadow: inset 0 0 1.35em darken($color, 7.5%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
    //background-color: saturate(darken($color, 2.5%), -20%);
  }
}

@mixin bt_ghost {
  @include bt-base(#fff, #fff);

  background-color: transparent;
  border-radius: 14px;
  border: 2px solid rgb(255, 255, 255);

  &:hover {
    box-shadow: 0 0.1em 0.4em 0 rgba(0, 0, 0, 0.35);
  }
}
