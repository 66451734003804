.primary_btn {
    display: inline-block;
    zoom: 1;
    border-radius: 14px;
    line-height: 20px;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    -webkit-user-drag: none;
    user-select: none !important;
    box-sizing: border-box;
    outline: 0 !important;
    position: relative;
    transition: all 0.2s ease;
    padding: 13px 15px;
    font-weight: 400;
    max-height: 52px;
    align-self: center;
    border: none;


    @include desktop {
        font-size: 125%;
    }

    &.white {
        font-weight: 500;
        background-color: #ffffff;
        padding: 0.6875em 0.9375em;
        @include prop-box-shadow(0 0.1em 0.4em 0 rgba(0, 0, 0, 0.15));
    }

    &.icon {
        background: #fff;
        @include prop-box-shadow(0 0.1em 0.4em 0 rgba(0, 0, 0, 0.15));
        padding: 11px;
        vertical-align: bottom;
    }

    &.orange {
        color: #fff;
        background-color: $primary_orange;
    }

    &.green {
        color: #fff;
        background-color: $primary_green;
    }

    &.ghost-white {
        background-color: transparent;
        border: 2px solid #ffffff;
        color: #fff;
    }

    &.ghost-green {
        background-color: transparent;
        border: 1px solid $primary_green;
        color: $primary_green;
    }

    &:hover {
        @include prop-box-shadow(0 0.1em 0.4em 0 rgba(0, 0, 0, 0.35));
    }

    &:disabled, &[disabled]{
        cursor: not-allowed;
        opacity: 0.66;
        box-shadow: none !important;
    }
}