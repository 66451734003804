@import "../sassdash/sassdash";

@if not function-exists(_encode) {
  @error 'Sassdash not found. Please import Sassdash in your project before importing Sass-SVG.';
}

$_SVG_STACK: null !global;
$_SVG_DEFAULT_ATTRS: (xmlns: 'http://www.w3.org/2000/svg') !global;

@function svg-render($element, $args...) {
  $type: _get($element, 'type');
  $attrs: _($element,
          _get 'attrs',
          _map svg-render-attr,
          _join ' ');
  $children: if(length(_get($element, 'children')) > 0,
          _($element,
                  _get 'children',
                  _map svg-render,
                  _join),
          _get($element, 'attrs.content'));

  @return if($children and _size($children) > 0,
          '<#{$type} #{$attrs}>#{$children}</#{$type}>',
          '<#{$type} #{$attrs}/>');
}

@function svg-render-attr($value, $key, $args...) {
  @if (type-of($value) != 'color') {
    $value: _replace($value, '\A', ' ');
  }

  @return '#{$key}="#{$value}"';
}

/// @link http://sassmeister.com/gist/1b4f2da5527830088e4d
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

/// borrowed from @link https://github.com/waldemarfm/sass-svg-uri/blob/master/_svg-uri.scss
@function svg-uri($svg) {
  $encoded: '';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg) / $slice);

  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    $chunk: str-replace($chunk, '"', "'");
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');
    $chunk: str-replace($chunk, '&', '%26');
    $chunk: str-replace($chunk, '#', '%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }

  @return $encoded;
}

@mixin svg($type: 'svg', $attrs: null) {
  $previous: $_SVG_STACK;
  $_SVG_STACK: () !global;

  @content;

  // Argument juggling
  @if (type-of($type) == 'map'
    and not $attrs
    and not $previous
  ) {
    $attrs: $type;
    $type: 'svg';
  }

  $element: (
          type: $type,
          attrs: $attrs,
          children: $_SVG_STACK
  );

  @if $previous != null {
    $_SVG_STACK: $previous !global;

    $_SVG_STACK: append($_SVG_STACK, $element) !global;
  } @else {
    $element-attrs: map-merge(_get($element, 'attrs'), (xmlns: 'http://www.w3.org/2000/svg'));

    $element: map-merge($element, (attrs: $element-attrs));

    background-image: url("data:image/svg+xml;charset=utf8,#{svg-uri(svg-render($element))}") !important;

    $_SVG_STACK: null !global;
  }
}
