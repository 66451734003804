$box-shadow-padding: 0.5em;

.image-gallery {
    display: grid;
    grid-template-columns: calc(23.12% + #{$box-shadow-padding}) 1.375em auto;
    grid-template-rows: auto;

    > * {
        grid-row: 1;
    }

    img {
        width: 100%;
        border-radius: 12px;
    }

    .thumbnails {
        grid-column: 1;
        max-height: calc(600px + #{$box-shadow-padding});
        overflow-y: scroll;
        padding: $box-shadow-padding;

        &.scrolled {
            margin-top: $box-shadow-padding;
            padding-top: 0;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        img {
            height: auto;
            cursor: pointer;
            transition: box-shadow 0.3s;

            &:hover {
                box-shadow: 0px 2px $box-shadow-padding 0px rgba(0, 0, 0, 0.3);
            }

            &:not(:last-child) {
                margin-bottom: 1.875em;
            }
        }
    }

    .selected-image {
        grid-column: 3;
        align-self: flex-start;
        margin: $box-shadow-padding 0;

        img {
            max-height: calc(600px - calc(#{$box-shadow-padding} / 2 ));
        }
    }
}

@include mobile() {
    .image-gallery {
        grid-template-columns: 100%;
        grid-template-rows: auto 10px auto;

        > * {
            grid-column: 1 !important;
        }

        .thumbnails {
            grid-row: 3;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            img {
                width: 168px;
                max-height: 110px;
                margin-bottom: 0;
                margin-right: 0.625em;
            }
        }

        .selected-image {
            grid-row: 1;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
