.portal {
    .hero {
        @include hero($gradient_blue);
        padding-bottom: 6.25em;
        margin-bottom: 0px;

        img {
            height: 80px;
        }
    }

    .search, .result-mobile {
        display: none;
    }

    .map-container {
        position: relative;
        width: 100%;
        height: auto;
        padding-bottom: 15%;
    }

    .card-overlay {
        width: 50.7%;
        height: 100%;
        overflow: scroll;
        background: rgba(255, 255, 255, 0.6);
        padding: 1.875em 1.8em 1.8em 1em;

        position: absolute;
        top: 0;
        z-index: 5;
    }

    .divider {
        width: 100%;
        height: 2px;
        background: rgb(237, 239, 240);
    }

    .grid-container {
        @include container();
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        > * {
            width: 49%;
            margin-left: 0;
        }
    }
}

@include mobile() {
    .portal {
        .hero {
            padding-bottom: 3.25em;

            img {
                height: 40px;
            }
        }

        .result-mobile {
            @include containerMobile(2.5em, 0);
            display: block;
            font-size: 120%;
            font-weight: 700;

            &.grid {
                @include containerMobile(0, 1em);
            }
    
            .city {
                color: rgb(214, 216, 217);
            }
        }

        .search-wrapper {
            @include containerMobile(0.3em, 1em);
            input {
                display: block;
                width: 100%;
                height: 52px;
                background: rgb(247, 247, 247);
                background-size: 1.375em 1.375em;
                background-image: url('../../assets/icons/search.svg');
                background-repeat: no-repeat;
                background-position: right 1em center;
            }
        }

        .map-container {
            padding-bottom: 0;
            overflow: hidden;

            .map-button-group {
                position: absolute;
                top: 1.25em;
                right: 0;
                z-index: 9;

                > * {
                    margin-right: 1.25em;
                }
            }
        }

        .grid-container {
            @include containerMobile();
            justify-content: center;
    
            > * {
                width: 100%;
            }
        }

        .card-overlay {
            @include containerMobile(1.25em, 0);
            width: 100%;
            position: relative;

            > * {
                margin: 0 auto 1.875em auto;
            }

            &.fullscreen {
                position: fixed;
                background: none;
                top: auto;
                bottom: 0;
                left: 0;
                height: auto;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                overflow: scroll;
                width: 100% !important;

                > * {
                    min-width: 300px;
                    margin-left: 0;
                    padding: 0.9375em 1.25em;
                    margin-right: 0.625em;
                    margin: 0 0.625em 1.25em 0;

                    .brand {
                        width: 60px;
                    }

                    .address {
                        font-size: 75%;
                    }

                    .name {
                        max-width: 65%;
                    }

                    .appointments,
                    .primary_btn,
                    .divider,
                    :nth-child(6) {
                        display: none;
                    }
                }
            }
        }
    }
}
