@import "mixins";
@import "page";
@import "../styleguide/gradients";

.header-placeholder, .header {

  @include desktop {
    height: 5em;
  }

  @include mobile {
    height: 4em;
  }
}

.header {

  position: fixed;
  z-index: 100;
  width: 100%;
  transition: box-shadow 1s;

  @include desktop {
    @media (min-width: $max-page-width) {
      width: $max-page-width;
    }
  }

  background: $gradient-primary-to-secondary;
  display: grid;
  padding: 1em;

  @include desktop {

    grid-template-columns: auto 1fr auto 0.5em auto 0.5em auto 0.5em auto;
    grid-template-rows: auto;

    > * {
      color: $white;
      align-self: center;
      grid-row: 1;
    }

    > :nth-child(1) {
      grid-column: 1;
    }

    > :nth-child(2) {
      grid-column: 3;
      @include bt-light($white);
    }

    > :nth-child(3) {
      grid-column: 5;
      @include bt-light($white);
    }

    > :nth-child(4) {
      grid-column: 7;
      @include bt-light($white);
    }

    > :nth-child(5) {
      grid-column: 9;
      @include bt-light-bordered($white);
    }

    > :nth-child(6) {
      display: none;
    }
  }

  @include mobile {

    grid-template-columns: 1em auto 1fr auto 1em;
    grid-template-rows: auto;

    > * {
      color: $white;
      align-self: center;
      grid-row: 1
    }

    > :nth-child(1) {
      grid-column: 2
    }

    > :nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5) {
      display: none
    }

    > :nth-child(6) {
      grid-column: 4;
      width: 2.3em;
      height: 2.3em
    }
  }
}

.header-scrolled {
  box-shadow: 0 0 2em 0 rgba(0, 0, 0, .075), 0 0 0.5em 0 rgba(0, 0, 0, .1);
  transition: box-shadow 1s;
}
