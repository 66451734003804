/* autoprefixer grid: autoplace */

@import './normalize';
@import "../scss/default-dr-flex/styleguide";
@import "../scss/default-dr-flex/website";

@import './mixins';
@import './styleguide/colors';
@import './styleguide/gradients';
@import './styleguide/buttons';
@import './basics';

// Elements
@import './elements/header';
@import './elements/footer';
@import './elements/inputs';
@import './elements/map';
@import './elements/searchbar';
@import './elements/icon-row';
@import './elements/surgery-card';
@import './elements/dropdown';
@import './elements/intro';
@import './elements/doctor-card';
@import './elements/doctor-overview';
@import './elements/image-gallery';


// Pages
@import './pages/portal';
@import './pages/doctor-page';
