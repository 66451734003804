.doctor-card {
    width: 49%;
    height: auto;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    margin-bottom: 1.875em;
    display: grid;
    grid-template-columns: 60px 1.25em 1fr 5px 33px;
    grid-template-rows: auto;
    align-content: center;
    padding: 1em 1.25em;
    cursor: pointer;

    -webkit-user-select: none;    
    -moz-user-select: none;
    -ms-user-select: none;

    &:hover {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
    }

    @include mobile {
        width: 100% !important;
    }

    > *:not(.text) {
        grid-row: 1;
        align-self: center;
    }

    > :nth-child(1) {
        grid-column: 1;
        width: 60px;
        height: auto;
        border-radius: 50%;
    }

    > :nth-child(2) {
        grid-column: 3;
        width: 100%;

        .name {
            font-weight: 500;
        }

        .job {
            color: rgb(214, 216, 217);
        }
    }

    > :nth-child(3) {
        justify-self: flex-end;
        grid-column: 5;
    }

    > :nth-child(4) {
        grid-column: 3;
        grid-row: 2;

        &.active {
            display: block;
        }

        &.inactive {
            display: none;
        }
    }
       

    p {
        margin: 0;
    }

    .icon {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        transition: transform 300ms;

        &.active {
            transform: rotate(180deg);
        }
    }
}