$gap: 20px;

.searchbar {
    @include container(0.5em, 1.2em);
    width: 100%;
    display: grid;
    grid-template-columns: auto 0.4fr 180px $gap 180px $gap 180px $gap auto;
    grid-template-rows: 2.5em;

    .result {
        font-size: 120%;
        font-weight: 700;

        .city {
            color: rgb(214, 216, 217);
        }
    }

    > * {
        grid-row: 1;
    }

    > :nth-child(1) {
        align-self: center;
        grid-column: 1;
    }

    > :nth-child(2) {
        grid-column: 3;
    }

    > :nth-child(3) {
        grid-column: 5;
    }

    > :nth-child(4) {
        grid-column: 7;
    }

    > :nth-child(5) {
        grid-column: 9;
    }

    .view-options {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        img {
            cursor: pointer;
            width: 18px;
            height: 18px;
        }
    }
}

@include mobile {
    .searchbar {
        @include containerMobile(0, 1.2em);
        overflow-x: scroll;
        overflow-y: visible;
        z-index: 99999999;
        -webkit-overflow-scrolling: touch;
        grid-template-columns: auto auto 180px $gap 180px $gap 180px $gap auto;

        // ugly fix for horizontal scroll
        padding-bottom: 300px;
        margin-bottom: -280px;

        &::-webkit-scrollbar {
            display: none;
        }
    
        .result {
            display: none;
        }

        .view-options {
            padding-right: 20px;
         }
    }
}