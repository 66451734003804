.surgery-card {
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-bottom: 1.875em;
    padding: 1.875em;
    font-size: 100%;
    cursor: pointer;

    max-width: 582px;
    margin-left: auto;
    height: auto;

    &:hover {
        transition: all 300ms;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.3);
    }

    p {
        margin: 0.5em 0;
    }

    .name {
        display: inline-block;
        max-width: 75%;
        font-weight: 700;
    }

    .brand {
        width: 19.2%;
        height: auto;
        float: right;
        vertical-align: center;
    }
    
    .address {
        color: rgb(214, 216, 217);
        font-size: 87.5%;
    }

    .medicalSpeciality {
        box-sizing: border-box;
        background: $primary_green;
        color: #ffffff;
        font-size: 87.5%;
        padding: 0.2em 0.5em;
        border-radius: 10px;
    }

    .divider {
        height: 1px !important;
    }

    .appointments {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 1.25em;

        > div {
            box-sizing: border-box;
            background: rgb(247, 249, 250);
            border-radius: 15px;
            padding: 0.3em .62em;
            width: 60px;
            margin: 0 0.6em .62em 0;
        }
    }

    .primary_btn {
        font-size: 100%;
        padding: .62em .62em;
    }
}