@import '../normalize';
@import "../roboto";
@import "../roboto-slab";
@import "page";
@import "footer";
@import "mixins";
@import "header";

@include roboto-font-faces();
@include roboto-slab-font-faces();

body {
  font-size: 14.233px;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  @include desktop {
    font-size: calc(11px + .4vw);
    @media (min-width: $max-page-width) {
      font-size: 17.0667px;
    }
  }
}

@mixin h-base($font-size) {

  @include emphasis-font();
  display: block;
  color: inherit;
  line-height: inherit;
  font-size: $font-size;
  margin: 0;
}

h1, h2 {

  @include h-base(130%);

  @include desktop {
    @include h-base(180%)
  }
}

h3 {

  @include h-base(115%);

  @include desktop {
    @include h-base(140%)
  }
}

h4 {
  @include h-base(100%)
}

.icon-test {

  > div {
    height: 5em;
    width: 5em;
    background-color: #aaa;
    margin: 0.25em;
  }
}


a {
  text-decoration: inherit;
  color: inherit;
}
