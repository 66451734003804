input {
    /* height: 52px; */
    box-sizing: border-box;
    border-radius: 12px;
    background-color: #fff;
    border: none;
    padding-left: 15px;
    height: auto;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: rgb(214, 216, 217);
        font-size: 100%;
        font-weight: 400;
    }
}
