@import '../styleguide';

@mixin bt-light($color: $white) {

  @include bt-base($color, $color, 0.75em 1em);
  //border: solid 1px transparent;
  border-radius: 0.66em;
  outline: none;
  box-shadow: inset 0 0 0 0, inset 0 0 0 1px rgba($color, 0.0);

  &.active {
    box-shadow: inset 0 0 0 0, inset 0 0 0 1px #fff;
  }

  &:hover {
    box-shadow: 0 0.2em 0.6em 0 rgba(0, 0, 0, 0.15), inset 0 0 0 1px #fff;
  }

  &:active {
    box-shadow: inset 0 0 1.35em darken($color, 7.5%), inset 0 0 0 1px $color;
  }
}

@mixin bt-light-bordered($color: $white) {

  @include bt-base($color, $color, 0.75em 1em);
  border: solid 1px $color;
  border-radius: 0.66em;

  &:hover {
    border: solid 1px darken($color, 2.5%);
    box-shadow: 0 0.2em 0.6em 0 rgba(0, 0, 0, 0.15);
    //text-shadow: 0 0 0.35em darken($color, 25%);
  }

  &:active {
    transition: none;
    //background-color: lighten($color, 2.5%);
    //text-shadow: none;
    box-shadow: inset 0 0 1.35em darken($color, 7.5%);
  }
}

@mixin bt-filled($color: $white, $background: $gradient-primary-to-secondary) {

  @include bt-base($color, $color, 0.75em 1em);
  border: none;
  border-radius: 0.66em;
  text-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
  background: $background;

  &:hover {
    border: none;
    box-shadow: 0 0.2em 0.6em 0 rgba(0, 0, 0, 0.15);
    text-shadow: 0 0 0.35em rgba(0, 0, 0, 0.25);
  }

  &:active, &.active {
    transition: none;
    box-shadow: inset 0 0 .5em rgba(0,0,0,.55);
  }

  &:disabled, &[disabled]{
    cursor: not-allowed;
    opacity: 0.66;
    box-shadow: none !important;
  }
}

@mixin emphasis-font($font-weight:400) {
  font-family: 'Roboto Slab', serif;
  font-weight: $font-weight;
}

/* ----------------------------------------------------------------- */


@mixin inp-base() {
  outline: none;
  border: none;
}


@mixin inp-hover() {

  @include inp-base();

  $color1: lighten($primary, 20%);
  $color2: darken($secondary, 10%);

  box-shadow: 0 0 0 2px rgba($color2, 0.0), 0 0 0 0 rgba($color1, .75);
  transition: box-shadow 0.4s;

  &:hover, &.hover {
    box-shadow: 0 0 2px 2px rgba($color2, 0.0), 0 0 0 6px rgba($color1, .75);
    transition: box-shadow 0.2s;
  }

  &:focus, &.focus {
    box-shadow: 0 0 2px 2px rgba($color2, 1.0), 0 0 0 6px rgba($color1, .75);
    transition: box-shadow 0.2s;
  }
}

/* ----------------------------------------------------------------- */

@mixin desktop {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin grid-item-pos($col, $row) {
  grid-column: $col;
  grid-row: $row;
}

/* ----------------------------------------------------------------- */

@mixin side-shadow($side:'bottom', $opacity:0.2) {

  /* https://codepen.io/jkneb/pen/qgpJF */

  overflow: hidden;
  position: relative;
  height: 0.75em;

  &:after {
    position: absolute;
    content: '';
    display: block;
    @if ($side == 'bottom') {
      margin: -25px auto 0;
    } @else {
      margin: 8px auto 0;
    }
    width: 100%;
    height: 25px;
    border-radius: 125px / 12px;
    box-shadow: 0 0 8px rgba(0, 0, 0, $opacity);
  }
}

