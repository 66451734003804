@import "mixins";

footer {

  display: grid;

  a {
    display: block;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  @include desktop {

    grid-template-columns: 2fr auto 1fr auto 1fr auto 1fr auto 2fr;
    grid-template-rows: 6em auto 2em auto auto 6em;

    > :nth-child(1) {
      @include grid-item-pos(2, 2);
    }

    > :nth-child(2) {
      @include grid-item-pos(4, 2)
    }

    > :nth-child(3) {
      @include grid-item-pos(6, 2)
    }

    > :nth-child(4) {
      @include grid-item-pos(8, 2)
    }

    > :nth-child(5) {
      @include grid-item-pos(2 / span 7, 4)
    }

    > :nth-child(6) {
      @include grid-item-pos(2 / span 7, 5)
    }
  }

  @include mobile {

    $spacing: 1em;

    grid-template-columns: $spacing auto $spacing;
    grid-template-rows: $spacing auto $spacing auto $spacing auto $spacing auto $spacing auto $spacing;

    > :nth-child(1) {
      @include grid-item-pos(2, 2);
    }

    > :nth-child(2) {
      @include grid-item-pos(2, 4)
    }

    > :nth-child(3) {
      @include grid-item-pos(2, 6)
    }

    > :nth-child(4) {
      @include grid-item-pos(2, 8)
    }

    > :nth-child(5) {
      @include grid-item-pos(2, 10)
    }

    > :nth-child(6) {
      @include grid-item-pos(2, 11)
    }
  }

  > :nth-child(1) {

    text-align: center;

    > :nth-child(1) {
      width: 4em;
      height: 4em;
      display: inline-block;
    }

    > :nth-child(2) {
      @include emphasis-font();
      font-size: 180%;
    }
  }

  > :nth-child(6) {
    text-align: center;
    color: rgb(115, 115, 115);;
  }

  background-color: rgb(46, 49, 56);
  color: $white
}