
// Primary
$primary_blue: #0084f7;
$primary_green:  #00ca9d;
$primary_orange: #ffa400;
$primary_red :#ff4949;


// Secondary
$secondary_blue: #7ee8ea;
$secondary_green: #7eeaa6;
$secondary_orange: #ffbf49;