@import "../icon-mixins";
@import "mixins";

$max-page-width: 1500px;

$iconWhite: #fff;

.icon-logo-white {
  @include icon-logo ($iconWhite)
}

.icon-hamburger-white {
  @include icon-hamburger($iconWhite)
}

.icon-close-white {
  @include icon-close($iconWhite)
}

.logo {

  display: grid;
  grid-template-rows: auto;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.025);
  }

  > * {
    color: $white;
    align-self: center;
    grid-row: 1;
  }

  > :nth-child(1) {
    grid-column: 1;
  }

  > :nth-child(2) {
    @include emphasis-font();
    grid-column: 3;
  }

  @include desktop {

    grid-template-columns: auto 1em auto;

    > :nth-child(1) {
      width: 3em;
      height: 3em;
    }

    > :nth-child(2) {
      font-size: 166%;
    }
  }

  @include mobile {

    grid-template-columns: auto 0.66em auto;

    > :nth-child(1) {
      width: 2em;
      height: 2em;
    }

    > :nth-child(2) {
      font-size: 133%;
    }
  }
}

.page {

  @include desktop {

    @media (min-width: $max-page-width) {

      background-color: #eee;

      > * {
        background-color: #fff;
        margin: 0 auto;
        max-width: $max-page-width;
        box-shadow: 0 0 2em 0 rgba(0, 0, 0, .075), 0 0 0.5em 0 rgba(0, 0, 0, .15);
      }
    }
  }
;

  position: relative;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

.page-content {

  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto;
  min-height: 100vh;

  > * {
    grid-column: 1;
  }

  > :nth-child(1) {
    grid-row: 1;
  }

  > :nth-child(2) {
    grid-row: 2;
  }

  > :nth-child(3) {
    grid-row: 3;
  }

  .mobile-sidebar {

    z-index: 200;
    background: $gradient-primary-to-secondary;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .icon-close-white {
      $size: 3em;
      position: absolute;
      width: $size * 2;
      height: $size * 1.5;
      background-size: $size $size;
      right: 0;
      top: 0;
    }

    .items {

      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      text-align: left;
      padding: 3em;
      font-size: calc(5px + 2vw);

      .default-link {
        display: block;
        color: #fff;
        padding-top: 0.8em;
        padding-bottom: 0.8em;
        font-size: 140%;
      }

      .button-link {
        margin-top: 1em;
        margin-bottom: 1em;
        @include bt-light-bordered($white);
      }

      .additional-items {
        //font-size: 80%;
      }
    }
  }
}