.dropdown-container {
    position: relative;

    .dropdown {
        border: 1px solid rgb(235, 235, 235);
        background: #fff;
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        margin: 0.2em 0;
        cursor: pointer;

        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
    }

    &:before {
        content: '';
        position: absolute;
        right: 15px;
        top: 17px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 4px 4px;
        border-color: transparent transparent #7e7f80 transparent;
        z-index: 9;
    }

    &:after {
        content: '';
        position: absolute;
        right: 15px;
        top: 24px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: #7e7f80 transparent transparent transparent;
    }

    &.selected {
        &:before {
            border-color: transparent transparent $primary_blue transparent;
        }
        &:after {
            border-color: $primary_blue transparent transparent transparent;
        }

    }

    .optionList {
        position: absolute;
        width: 100%;
        max-height: 250px;
        overflow-y: scroll;
        top: 45px;
        z-index: 9999;

        background: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
        border-radius: 10px;

        > * {
            width: 100%;
            text-align: left;
            padding: 1em;
            border: none;
            background: none;
            outline: none;
            cursor: pointer;

            &:hover {
                background: $primary_blue;
                border-radius: 10px;
                color: #ffffff;
            }
        }
    }
}
