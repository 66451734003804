  
@mixin mobile {
    @media (max-width: 800px) {
        @content;
    }
}

@mixin container($top: 0, $bottom: 0) {
    padding: $top 6.4% $bottom 6.4%;
    max-width: 1440px;
    margin: 0 auto;
}

@mixin containerMobile($top: 0, $bottom: 0) {
    padding: $top 20px $bottom 20px;
}

@mixin prop-box-shadow($shadow...) {
    box-shadow: $shadow;
}

@mixin fadeInAnimation($time) {
    @-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    -webkit-animation: fadeIn $time;
            animation: fadeIn $time;

}

@mixin slideDownAnimation($time) {
    @keyframes slideDown {
        0%   {  transform:translateY(-10%)    }
        100%  {  transform:translateY(0) }
     }

     @-webkit-keyframes slideDown {
        0%   {  transform:translateY(-10%)    }
        100%   {  transform:translateY(0)    }
     }

     -webkit-animation: slideDown $time;
            animation: slideDown $time;
}


@mixin controlDots($color) {
    div {
        width: 0.8em;
        height: 0.8em;
        margin: 0.63em;
        border-radius: 50%;
        background-color: $color;
        display: inline-block;

        &.inactive {
            background-color: #d6d8d9;
        }
    }
}

@mixin hero($background-color: none) {
    width: 100%;
    height: auto;
    background: $background-color;
    position: relative;
    padding-bottom: 10%;
    margin-bottom: 50px;
}