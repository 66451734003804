.overview {
    max-width: 786px;
    height: auto;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 2.5em 3.125em 3em 3.125em;

    display: grid;
    grid-template-columns: 204px 20% auto 25px 100px;
    grid-template-rows: auto 5px auto 20px auto 30px auto;

    > :nth-child(1) {
        grid-column: 1 / 5;
        grid-row: 1;
        font-size: 200%;
    }

    > :nth-child(2) {
        grid-column: 5;
        grid-row: 1;
        width: 100%;
        height: auto;
    }

    > :nth-child(3) {
        grid-column: 1 / 6;
        grid-row: 3;
        color: rgb(184, 184, 184);
    }

    > :nth-child(4) {
        grid-column: 1;
        grid-row: 5;
    }

    > :nth-child(5) {
        grid-column: 1;
        grid-row: 7;
    }

    > :nth-child(6) {
        grid-column: 3 / 6;
        grid-row: 5 / 8;

        > :nth-child(odd) {
            margin-bottom: 0.3125em;
            &:not(:first-child) {
                margin-top: 1.875em;
            }
        }

        > a {
            padding-bottom: 1.875em;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    > :nth-child(4),
    > :nth-child(5) {
        > * {
            margin-bottom: 0.315em;

            &:not(:nth-child(1)) {
                color: rgb(184, 184, 184);
            }
        }
    }
}

@include mobile {
    .overview {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
        padding: 1.875em 1.25em;

        display: grid;
        grid-template-columns: auto 5px 60px;
        grid-template-rows: auto 5px auto 20px auto 20px auto 20px auto;

        > :nth-child(1) {
            grid-column: 1;
            grid-row: 1;
            font-size: 125%;
        }

        > :nth-child(2) {
            grid-column: 3;
            grid-row: 1;
        }

        > :nth-child(3) {
            grid-column: 1;
            grid-row: 3;
        }

        > :nth-child(4) {
            grid-column: 1;
            grid-row: 5;
        }

        > :nth-child(5) {
            grid-column: 1;
            grid-row: 7;
        }

        > :nth-child(6) {
            grid-column: 1;
            grid-row: 9;
        }
    }
}
