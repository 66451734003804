@mixin roboto-slab-font-faces($font-path:'/static/fonts') {

  // https://google-webfonts-helper.herokuapp.com/fonts/roboto-slab?subsets=latin-ext,latin

  /* roboto-slab-300 - latin_latin-ext */
  //@font-face {
  //  font-family: 'Roboto Slab';
  //  font-style: normal;
  //  font-weight: 300;
  //  src: local('Roboto Slab Light'), local('RobotoSlab-Light'),
  //  url($font-path + '/roboto-slab-v7-latin_latin-ext-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  //  url($font-path + '/roboto-slab-v7-latin_latin-ext-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  //}

  /* roboto-slab-regular - latin_latin-ext */
  @font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
    url($font-path + '/roboto-slab-v7-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url($font-path + '/roboto-slab-v7-latin_latin-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* roboto-slab-700 - latin_latin-ext */
  //@font-face {
  //  font-family: 'Roboto Slab';
  //  font-style: normal;
  //  font-weight: 700;
  //  src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'),
  //  url($font-path + '/roboto-slab-v7-latin_latin-ext-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  //  url($font-path + '/roboto-slab-v7-latin_latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  //}
}