/**
 * Color
 */

/* White */
$white: hsl(220, 10%, 100%);
$white-hover: color($white shade(5%));
$white-active: color($white shade(10%));

/* Black */
$black: hsl(220, 10%, 0%);
$black-hover: color($black tint(20%));
$black-active: color($black tint(10%));

/**
 * Translucent
 */

$black-50-alpha: hsla(220, 10%, 0%, 0.5);

/**
 * Shades
 */

/* Light */
$light: hsl(220, 10%, 80%);

/* Grey */
$grey: hsl(220, 10%, 40%);

/* Dark */
$dark: hsl(220, 10%, 20%);

/**
 * Palette
 */

/* Primary */
$primary: darken(hsl(146, 84%, 43%), 5%);
$primary-hover: color($black tint(10%));
$primary-active: color($black tint(10%));

/* Secondary */
$secondary:  darken(hsl(181, 78%, 46%), 5%);
$secondary-hover: color($black tint(10%));
$secondary-active: color($black tint(10%));

/* Tertiary */
$tertiary: hsl(208, 84%, 52%);
$tertiary-hover: color($black tint(10%));
$tertiary-active: color($black tint(10%));


/* Primary Addwords Landing Page*/
$primary_aw: #00ca9d;

/* Secondary Addwords Landing Page*/
$secondary_aw: #ffa400;

/**
 * Indicators
 */

/* Info */
$info: hsl(200, 100%, 40%);

/* Error */
$error: hsl(0, 100%, 40%);

/* Warning */
$warning: hsl(20, 100%, 50%);

/* Success */
$success: hsl(120, 100%, 35%);


$flex-dark-grey: #404040;
$flex-dark-light: #707070;
$flex-green: #648b0e;
$blue-link: #4285F4;

$box-shadow-light: 0 0 0.25em 0 rgba(0, 0, 0, 0.25);