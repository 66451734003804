.doctor-page {

    .hero {
        @include hero();
        padding-bottom: 15%;

        .intro {
            .headline {
                max-width: initial;
            }
        }
    }

    .informations {
        @include container();
        display: grid;
        grid-template-columns: 66% 1fr 31.5%;
        grid-template-rows: auto;

        > :nth-child(1) {
            grid-column: 1;
            grid-row: 1;
        }

        > :nth-child(2) {
            grid-column: 3;
            grid-row: 1;
            height: auto;
        }
    }

    .about {
        @include container(3.125em, 5em);

        h4 {
            font-size: 125%;
        }

        p {
            font-size: 100%;
            line-height: 26px;
            max-width: 786px;
            color: rgb(182, 183, 184);
            margin-top: 0.625em;
        }
    }

    .doctors {
        @include container();

        h3 {
            font-size: 200%;
            margin-bottom: 0.94em;
        }

        .doctors-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;

        }
    }

    .surgery-images {
        @include container(3.75em, 0);

        h3 {
            font-size: 200%;
            margin-bottom: 0.6875em;
        }
    }

    .seals {
        .headline {
            color: #000000;
        }
    }
}

@include mobile {
    .doctor-page {
        .informations {
            width: 100%;
            padding: 0;
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: auto 500px;

            > :nth-child(1) {
                margin: 0 1.25em;
                grid-column: 1;
                grid-row: 1;
                margin-bottom: -4em;
                z-index: 9;
            }

            > :nth-child(2) {
                grid-column: 1;
                grid-row: 2;
                width: 100vw;
                border-radius: 0px;
            }
        }

        .about {
            @include containerMobile(2.5em, 1.875em);
    
            h4 {
                font-size: 100%;
            }
        }


        .doctors {
            @include containerMobile();

            h3 {
                font-size: 125%;
            }

            .doctors-container {
                justify-content: center;
            }
        }

        .surgery-images {
            @include containerMobile(1.875em, 0);

            h3 {
                font-size: 125%;
            }
        }
        
    }
}
