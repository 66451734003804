@import '../scss/default-dr-flex/roboto';

@include roboto-font-faces();

.app {
    min-height: 100vh;
    position: relative;
}

body {
    font-size: 100%;
}

p {
    margin: 20px 0 20px 0;
    line-height: 1.5;
}

a {
    text-decoration: inherit;
    color: inherit;
}


h1,
h2,
h3,
h4 {
    font-family: 'Roboto';
    margin: 0;
    padding: 0;
    display: block;
}

h3 {
    font-weight: 700;

    @include desktop {
        font-size: 150%;
    }

    @include mobile {
        font-size: 125%;
    }
}

h4 {
    font-weight: 500;

    @include desktop {
        font-size: 125%;
    }

    @include mobile {
        font-size: 100%;
    }
}

.bg {
    width: 100%;
    position: absolute;
    z-index: 9;

    //left: -1px;
    &.bottom {
        bottom: -2px;
    }

    &.top {
        top: -2px;
    }

    @include mobile {
        width: 100%;
        left: 0;
        right: 0;
    }
}

.divider {
    width: 100%;
    height: 1px;
    background: rgb(237, 239, 240);
    margin-bottom: 1em;
}

.divider-vertical {
    width: 1px;
    height: 36px;
    background: rgb(237, 239, 240);
    margin: 0 .6em;
}

$max-page-width: 1920px;


@media (min-width: $max-page-width) {
    .app {
        max-width: $max-page-width;
        margin: 0 auto;
        @include prop-box-shadow(0 0 2em 0 rgba(0,0,0,.075), 0 0 0.5em 0 rgba(0,0,0,.15));
    }
}