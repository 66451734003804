$base-zIndex: 20;

header {
    @include container(20px, 0px);
    display: grid;

    > * {
        grid-row: 1;
        align-self: center;
        position: relative;
    }

    // TODO: new
    &.default {
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto;

        > :nth-child(1) {
            grid-column: 1;
            grid-row: 1;
        }

        > :nth-child(2) {
            grid-column: 3;
        }
    }

    &.header-portal {
        grid-template-columns: 40px 20px 30% 1fr auto;

        > :nth-child(1) {
            grid-column: 1;
        }

        > :nth-child(2) {
            grid-column: 3;
            height: 40px;
            margin-right: 1em;
            background-size: 1.375em 1.375em;
            background-image: url('../../assets/icons/search.svg');
            background-repeat: no-repeat;
            background-position: right 1em center;
        }

        > :nth-child(3) {
            grid-column: 5;
        }

        .brand {
            display: block;
            .brand_title {
                display: none;
            }
        }
        
    }

    .menu {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        :nth-child(3) {
            a {
                padding-bottom: 1.25em;
            }
        }

        > *:not(:last-child) {
            padding-right: 1.875em;
        }
    }

    a {
        color: #ffffff;

        &:hover {
            font-weight: 700;
        }

        &.is-active {
            font-weight: 700;
        }
    }

    .brand {
        display: grid;
        grid-template-columns: auto 1em auto;
        grid-template-rows: 1fr;
        cursor: pointer;
        z-index: $base-zIndex * 2;

        .icon-logo-white {
            width: 40px;
            height: 40px;
            grid-column: 1;
        }

        .brand_title {
            grid-column: 3;
            align-self: center;
            color: rgb(248, 248, 248);
            font-family: 'Roboto';
            font-size: 150%;
            font-weight: 700;
        }

        &:hover {
            transform: scale(1.025);
        }
    }
}

.menuButton,
.menuOverlay {
    display: none;
}

.submenu {
    @include slideDownAnimation(0.5s);
    position: absolute;
    background: #fff;
    top: 30px;
    left: 130px;
    list-style: none;
    padding: 20px 30px 20px 30px;
    z-index: $base-zIndex;
    border-radius: 10px;
    margin: 0;
    @include prop-box-shadow(0px 2px 20px 0px rgba(0, 0, 0, 0.1));
    a {
        color: $primary_green;
    }

    > * {
        padding: 8px 0;
        white-space: nowrap;
    }

    @include mobile {
        position: relative;
        top: inherit;
        left: inherit;
        background: none !important;
        box-shadow: none;
        padding: 0;
    }
}

@include mobile {
    .menuOverlay {
        display: block;
        width: 100%;
        position: absolute;
        //background: $gradient_green;
        background-color: #fff;
        top: 0 !important;
        left: 0;
        z-index: $base-zIndex;
        transition: height 0.5s ease-in-out;

        .fill {
            height: 20vh;
            width: 100vw;
            background: #fff;
            position: absolute;
            bottom: -20vh;
            @include fadeInAnimation(0.5s);
        }

        .menuMobile {
            grid-template-columns: 100%;
            grid-template-rows: auto;
            padding: 110px 0 0 30px;
            @include fadeInAnimation(0.5s);
            row-gap: 30px;
            z-index: $base-zIndex;

            > a {
                grid-column: 1;
                font-size: 157%;
                font-weight: 700;
                z-index: $base-zIndex;
            }

            > div {
                color: #fff;
                font-weight: 300;
            }

            > :nth-child(1) {
                grid-row: 1;
            }

            > :nth-child(2) {
                grid-row: 2;
            }

            > :nth-child(3) {
                grid-row: 3;
            }

            > :nth-child(4) {
                grid-row: 4;
                margin-bottom: 20px;
            }

            > :nth-child(5) {
                grid-row: 5;
            }

            > :nth-child(6) {
                grid-row: 6;
            }
        }
    }
}

@include mobile {
    header {
        // TODO: new
        &.default {
            > :nth-child(2) {
                display: none;
            }

            > :nth-child(3) {
                grid-column: 3;
            }
        }

        &.header-portal {
            grid-template-columns: auto 1fr auto;

            > :nth-child(2) {
                display: none;
            }

            > :nth-child(3) {
                display: none;
            }

            > :nth-child(4) {
                grid-column: 3;
            }
        }
    }

    .menuButton {
        height: 32px;
        width: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 4px;
        z-index: $base-zIndex * 2;

        .line {
            height: 2px;
            width: 20px;
            background: #fff;
            transition: all 0.2s ease;
        }
    }
}
