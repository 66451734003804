.map {
    width: 100%;
    height: 650px;
    z-index: 1;
    font-family: 'Roboto', sans-serif;
    font-size: 100%;

    &.fullscreen {
        height: calc(100vh - 170px);
        
    }

    &.small {
        border-radius: 12px;
    }
}

.leaflet-popup-tip {
    width: 0px;
    height: 0px;
}

.leaflet-marker-icon {
    width: 42px;
    height: 42px;
}

.leaflet-touch .leaflet-bar {
    border: none;
    margin: 2em 2em 0 0;

    & .leaflet-control-zoom-in {
        border-radius: 12px 12px 0 0 !important;
    }

    & .leaflet-control-zoom-out {
        border-radius: 0 0 12px 12px !important;
    }
}

.leaflet-marker-icon {
    -webkit-filter: drop-shadow(2px 2px 10px rgba(0,0,0,0.3));      
    filter: drop-shadow(2px 2px 10px rgba(0,0,0,0.3));  
}

.popup-container {
    width: 205px;
    height: auto;
    background: #ffffff;
    border-radius: 10px;

    .name {
        display: inline-block;
        font-size: 100%;
        font-weight: 700;
        padding-bottom: 1em;
        max-width: 80%;
    }

    .brand {
        max-width: 20%;
        vertical-align: top;
    }

    .appointments {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        > div {
            background: rgb(247, 249, 250);
            border-radius: 15px;
            padding: 0.3em .62em;
            width: 60px;
            margin: 0 0.3em .62em 0;
            font-size: 87.5%;
        }
    }

    .primary_btn {
        font-size: 100%;
        width: 100%;
        padding: .62em .62em;
    }
}

@include mobile() {
    .map {
        width: 100%;
        height: 286px;
    }

    .leaflet-marker-icon {
        width: 27px;
        height: 27px;
    }

    .leaflet-popup {
        display: none;
    }

    .leaflet-touch .leaflet-bar {
        display: none;
    }
}